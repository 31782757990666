<template>
  <v-app-bar app clipped-right dense color="secondary" dark height="70" elevation="0">

    <!-- SHOW / HIDE SIDE-BAR BTN -->
    <v-app-bar-nav-icon @click.stop="drawer = !drawer; $emit('update-drawer', drawer)" />

    <!-- APP-BAR TITLE -->
    <v-toolbar-title class="py-5 align-center">
      <span class="title">لوحة التحكم</span>
    </v-toolbar-title>

    <v-spacer />
    <v-btn color="white" light elevation="0" @click="$auth.logout({ redirect: '/login' })">خروج</v-btn>
  </v-app-bar>
</template>


<script>
export default {
  name: 'app-bar',

  data: function() {
    return {
      drawer: true
    };
  }
};
</script>