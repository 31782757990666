<template>
  <v-footer color="secondary" app>
    <span class="white--text">AlexApps &copy; {{ (new Date).getFullYear() }}</span>

    <v-spacer />

    <v-chip color="secondary lighten-2">
      <span dir="ltr">{{ require("@/../package.json").version }}</span>
      <v-avatar right class="secondary">V</v-avatar>
    </v-chip>
  </v-footer>
</template>


<script>
export default {
  name: "app-footer"
};
</script>