<template>
  <v-navigation-drawer :value="drawer" app clipped dark right>
    <v-list>
      <span v-for="navItem in navList" :key="navItem.text">
        <!-- NAV-ITEM HAS SUB-ITEMS -->
        <v-list-group
          v-if="navItem.subItems"
          no-action
          active-class="white--text"
        >
          <template #prependIcon>
            <v-icon>{{ navItem.icon }}</v-icon>
          </template>

          <template #activator>
            <v-list-item-content>
              <v-list-item-title>{{ navItem.text }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            link
            dense
            ripple
            class="pr-5"
            active-class="white--text"
            :to="subItem.link"
            :key="subItem.title"
            v-for="subItem in navItem.subItems"
            left
          >
            <v-list-item-action class="ml-2">
              <v-icon color="secondary lighten-3"
                >mdi-subdirectory-arrow-left</v-icon
              >
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ subItem.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!-- SINGLE NAV-ITEM -->
        <v-list-item v-else link :to="navItem.link">
          <v-list-item-action>
            <v-icon>{{ navItem.icon }}</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>{{ navItem.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </span>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "side-bar",
  props: ["drawer"],

  data: function () {
    return {
      navList: [
        {
          link: "/home",
          icon: "mdi-monitor-dashboard",
          text: "الصفحة الرئيسية",
        },
        {
          link: "/users",
          icon: "mdi-account",
          text: "العملاء",
        },
        {
          link: "/areas",
          icon: "mdi-map",
          text: "المناطق",
        },
        {
          link: "/models",
          icon: "mdi-view-dashboard",
          text: "الموديلات",
        },
        {
          link: "/admins",
          icon: "mdi-tie",
          text: "مديري التطبيق",
        },
        {
          link: "/sections",
          icon: "mdi-monitor-dashboard",
          text: "الاقسام",
        },
        {
          link: "/categories",
          icon: "mdi-sitemap",
          text: "التصنيفات الرئيسية",
        },
        {
          link: "/subcategories",
          icon: "mdi-sitemap",
          text: "التصنيفات الفرعية",
        },
        {
          link: "/userAds",
          icon: "mdi-sitemap",
          text: "الاعلانات",
        },
        {
          link: "/commercialAds",
          icon: "mdi mdi-video-vintage",
          text: "اعلانات التجارية",
        },
        {
          link: "/sliders",
          icon: "mdi-cable-data",
          text: "اعلانات البوسترات",
        },
        {
          link: "/banners",
          icon: "mdi-cable-data",
          text: "اللوحات الإعلانية",
        },
        {
          link: "/AdvertisingRatesLists",
          icon: "mdi-cable-data",
          text: "اسعار الاعلانات ",
        },
        {
          link: '/notifications',
          icon: 'mdi mdi-bell',
          text: 'الاشعارات',
        },
        {
          link: '/wallet_balances',
          icon: 'mdi-wallet',
          text: 'المحفظة و الأرصدة',
        },
        {
          link: '/wallet_transactions?userId=',
          icon: 'mdi-receipt',
          text: 'عمليات المحفظة',
        },
        {
          link: '/admin/pages/terms',
          icon: 'mdi-cellphone-cog',
          text: 'الاعدادات',
        },
      ]

    };
  },
};
</script>
