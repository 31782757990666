<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <!-- TITLE -->
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="grey--text text--lighten-3">{{ title }}</v-toolbar-title>
      </v-toolbar>

      <!-- MESSAGE -->
      <v-card-text v-show="!!message" class="pa-4">{{ message }}</v-card-text>

      <!-- ACTION -->
      <v-card-actions class="grey lighten-3">
        <v-spacer></v-spacer>
        <v-btn :color="options.color" dark elevation="1" @click.native="agree">Agree</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
export default {
  data: function () {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
    
      options: {
        color: "primary",
        width: 350,
        zIndex: 200,
      }
    }
  },


  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    agree() {
      this.resolve(true);
      this.dialog = false;
    },

    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>