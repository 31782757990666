<template>
  <div>
    <!-- SIDE BAR -->
    <side-bar :drawer="drawer" />

    <!-- APP BAR -->
    <app-bar @update-drawer="drawer = $event" />

    <!-- CONTENT -->
    <v-main>
      <v-container class="mt-9 px-9">
        <router-view :key="$route.fullPath" />
      </v-container>
    </v-main>

    <!-- APP FOOTER -->
    <app-footer />

    <!-- SNACK BAR NOTIFIER -->
    <snackbar-notifier />

    <!-- CONFIRM MESSAGE DIALOG -->
    <confirm ref="confirm"></confirm>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar';
import SideBar from '@/components/SideBar';
import Confirm from '@/components/Confirm';
import AppFooter from '@/components/AppFooter';
import SnackbarNotifier from '@/components/SnackbarNotifier';

export default {
  name: 'admin-app',
  components: { AppBar, SideBar, AppFooter, Confirm, SnackbarNotifier },

  data: function () {
    return {
      drawer: true,
    };
  },

  mounted() {
    this.$bus.$emit('showSnackbar', {
      text: 'تم الدخول إلي لوحة التحكم بنجاح',
      color: 'primary',
    });

    this.$root.$confirm = this.$refs.confirm.open;
  },
};
</script>
