<template>
  <div>
    <v-snackbars :objects.sync="objects" right bottom :timeout="3000">
      <template v-slot:action="{ close }">
        <v-btn small text @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbars>
  </div>
</template>


<script>
import VSnackbars from "v-snackbars";

export default {
  name: "snack-bar-notifier",
  components: { VSnackbars },

  data: function() {
    return {
      objects: []
    };
  },

  created() {
    this.$bus.$on("showSnackbar", options => {
      this.objects.push({
        message: options.text,
        color: options.color
      });
    });
  }
};
</script>